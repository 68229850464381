/* eslint-disable no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import videojs from '@mux/videojs-kit/dist/index.vhs';
import PlayRateSlider, { playbackRates } from './PlayRateSlider';
import NextActionPoster from './NextActionPoster';
import PreviewStartOverlay from './PreviewStartOverlay';
import CourseSummaryOverlay from './CourseSummaryOverlay';
import ajaxError from '../../lib/utils/ajaxError';
import client from '../../lib/api/axiosClient';
import { getLocalStorage, setLocalStorage } from '../../lib/utils/localStorage';

const VideoPlayer = ({
  playbackId,
  subject = null,
  nextAction = null,
  showPlayRate = false,
  timecode = null,
  videoPreviewTime = null,
  previewOnly = false,
  isFirstLesson = false,
  handleExternalEvent = false, // set this prop if you want to handle Non-React events.
  course = null,
  viewingId = null,
  onTimeUpdate = null,
}) => {
  const defaultPlayRate = 1;
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const previousSecondsWatchedRef = useRef(0);
  const viewingCompletionRegisteredRef = useRef(false);
  const uniqueWatchSecondsSetRef = useRef(new Set());

  const [showNextActionPoster, setShowNextActionPoster] = useState(false);
  const [videoPreviewVisible, setVideoPreviewVisible] = useState(previewOnly && !isFirstLesson);
  const [summaryOverlayVisible, setSummaryOverlayVisible] = useState(isFirstLesson);
  const [playbackSpeed, setPlaybackSpeed] = useState(parseFloat(getLocalStorage('KUBE.playRate') || defaultPlayRate));

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
  });

  const videoJsOptions = {
    preload: 'auto',
    width: '100%',
    controls: true,
    playbackRates,
    timelineHoverPreviews: true,
    poster: `https://image.mux.com/${playbackId}/thumbnail.jpg?time=6`,
    sources: [{
      src: playbackId,
      type: 'video/mux',
    }],
  };

  const updateViewing = secondsWatched => {
    const previousSecondsWatched = previousSecondsWatchedRef.current;
    const viewingCompletionRegistered = viewingCompletionRegisteredRef.current;
    const player = playerRef.current;

    if (secondsWatched > previousSecondsWatched) {
      previousSecondsWatchedRef.current = secondsWatched;

      const hasWatchedEightyPercent = (secondsWatched >= Math.floor((80 / 100) * player.duration()));
      const markAsComplete = (hasWatchedEightyPercent && !viewingCompletionRegistered);

      if (markAsComplete || secondsWatched % 10 === 0) {
        client
          .put(`/api/v1/viewings/${viewingId}.json`, {
            seconds_watched: secondsWatched,
            complete: markAsComplete,
          })
          .catch(error => {
            if (error.response?.status === 401) ajaxError(error.response.status);
            else console.error(error);
          });

        viewingCompletionRegisteredRef.current = viewingCompletionRegistered || markAsComplete;
      }
    }
  };

  const disposePlayer = player => {
    if (player && !player.isDisposed()) {
      player.dispose();
      playerRef.current = null;
    }
  };

  useEffect(() => {
    if (handleExternalEvent) KUBE.videoPlayer = 'mux'; // exposing player identifier to KUBE for handling Non-React (JQuery) events.
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered', 'video-js', 'vjs-16-9', 'vjs-theme-kubicle');
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, videoJsOptions);
      const player = playerRef.current;

      const settings = player.textTrackSettings;
      settings.setValues({
        backgroundColor: '#000',
        backgroundOpacity: '0.5',
      });
      settings.updateDisplay();

      player.on('ready', () => {
        if (handleExternalEvent) KUBE.mux.player = player; // exposing player to KUBE for handling Non-React (JQuery) events.
        if (timecode) player.currentTime(timecode);
        player.volume(getLocalStorage('KUBE.volume'));
        player.playbackRate(getLocalStorage('KUBE.playRate'));
      });

      player.on('pause', () => {
        const playButton = playerRef.current.getChild('BigPlayButton');
        if (playButton) playButton.show();
      });

      player.on('play', () => {
        const playButton = playerRef.current.getChild('BigPlayButton');
        if (playButton) playButton.hide();
      });

      player.on('ended', () => {
        if (nextAction) setShowNextActionPoster(true);
        if (player.isFullscreen()) player.exitFullscreen();
      });

      player.on('timeupdate', () => {
        const currentTimeSecond = Math.floor(player.currentTime());
        uniqueWatchSecondsSetRef.current.add(currentTimeSecond);
        const totalUniqueSecondsWatched = uniqueWatchSecondsSetRef.current.size;
        if (viewingId !== null) updateViewing(totalUniqueSecondsWatched);
        if (onTimeUpdate) onTimeUpdate(playerRef.current.currentTime());
        if (previewOnly && totalUniqueSecondsWatched >= videoPreviewTime) {
          disposePlayer(player);
          setShowNextActionPoster(true);
        }
      });

      player.on('volumechange', () => {
        let volume = player.volume();
        if (player.muted()) volume = 0;
        setLocalStorage('KUBE.volume', volume);
      });

      player.on('ratechange', () => {
        const speed = player.playbackRate();
        setPlaybackSpeed(speed);
        setLocalStorage('KUBE.playRate', speed);
      });
    } else {
      const player = playerRef.current;

      player.src(videoJsOptions.sources);
    }
  }, [videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      disposePlayer(player);
    };
  }, [playerRef]);

  // Pause the video when view hides/goes out from screen
  React.useEffect(() => {
    const player = playerRef.current;
    if (inView) return;
    if (player.isDisposed()) return;

    if (!player.paused()) player.pause();
  }, [inView]);

  const handleSliderChange = value => {
    playerRef.current.playbackRate(value);
  };

  const handlePreviewStartClick = useCallback(() => {
    setVideoPreviewVisible(false);
    playerRef.current.play();
  }, [setVideoPreviewVisible]);

  const handleCourseSummaryClick = useCallback(() => {
    setSummaryOverlayVisible(false);
    playerRef.current.play();
  }, [setSummaryOverlayVisible]);

  return (
    <div>
      <div
        ref={inViewRef}
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <div>
            <div data-vjs-player className={`video_wrapper_${playbackId}`}>
              <div ref={videoRef} />
            </div>
          </div>
        </div>

        { summaryOverlayVisible && <CourseSummaryOverlay course={course} handleOnClick={handleCourseSummaryClick} /> }
        { videoPreviewVisible && <PreviewStartOverlay handleOnClick={handlePreviewStartClick} /> }
        { nextAction && <NextActionPoster {... { showNextActionPoster, nextAction, subject }} /> }
      </div>

      {showPlayRate && (
        <div className="playrate-slider-container">
          <PlayRateSlider selectedValue={playbackSpeed} handleOnSliderChange={handleSliderChange} />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
